/* eslint-disable max-len */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components/macro'

import Layout from '../components/layout'
import SEO from '../components/seo'

import configs from '../../site-config'

const Container = styled.div`
  margin: auto;
  max-width: ${configs.content_width};
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 50px;
`

const MainTitle = styled.h1`
  padding: 50px 0;
`

const Title = styled.h2`
  padding-bottom: 20px;
`

const List = styled.ul`
  padding-bottom: 20px;
  margin-left: 40px;
  color: #333333;
`

const Paragraph = styled.p`
  padding-bottom: 20px;
  line-height: 1.5;
  color: #333333;
`

const Privacy = ({ data }) => (
  <Layout>
    <SEO title="Privacy Policy" />

    <div
      style={{
        backgroundImage: `linear-gradient(${configs.cover_overlay_color_rgba},${
          configs.cover_overlay_color_rgba
        }),url(${data.headerImage.childImageSharp.fluid.src})`,
      }}
    >
      <div className="headerBackground">
        <Container>
          <header>
            <div className="logo">
              <div className="appIconShadow">
                <Img
                  fluid={data.headerIcon.childImageSharp.fluid}
                  className="headerIcon"
                />
              </div>
              <p className="headerName">{configs.app_name}</p>
            </div>
          </header>

          <MainTitle>Privacy Policy</MainTitle>

          <Paragraph>
            Dot Dot Data LLC built the Pointbee app as
            a Commercial app. This SERVICE is provided by
            Dot Dot Data LLC  and is intended for
            use as is.
          </Paragraph>

          <Paragraph>
            This page is used to inform visitors regarding
            our policies with the collection, use, and
            disclosure of Personal Information if anyone decided to use
            our Service.
          </Paragraph>

          <Paragraph>
            If you choose to use our Service, then you agree
            to the collection and use of information in relation to this
            policy. The Personal Information that we collect is
            used for providing and improving the Service.
            We will not use or share your
            information with anyone except as described in this Privacy
            Policy.
          </Paragraph>

          <Paragraph>
            The terms used in this Privacy Policy have the same meanings
            as in our Terms of Use, which is accessible at
            Pointbee unless otherwise defined in this Privacy
            Policy.
          </Paragraph>

          <Title>Information Collection and Use</Title>

          <Paragraph>
            For a better experience, while using our Service,
            we may require you to provide us with certain
            personally identifiable information, including but not limited to email address, name, device information, device location. The
            information that we request will be
            retained by us and used as described in this privacy policy.
          </Paragraph>

          <Paragraph>
            The app does use third party services that may collect
            information used to identify you.
          </Paragraph>

          <Paragraph>
            Links to privacy policy of third party service providers
            used by the app:
          </Paragraph>

          <List>
            <li><a href="https://www.google.com/policies/privacy/" rel="noopener noreferrer" target="_blank">Google Play Services</a></li>
            <li><a href="https://firebase.google.com/policies/analytics" rel="noopener noreferrer" target="_blank">Firebase Analytics</a></li>
            <li><a href="https://www.facebook.com/about/privacy" rel="noopener noreferrer" target="_blank">Facebook</a></li>
          </List>

          <Title>Log Data</Title>

          <Paragraph>
            We want to inform you that whenever
            you use our Service, in a case of an error in the
            app we collect data and information (through third
            party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet
            Protocol (“IP”) address, device name, operating system
            version, the configuration of the app when utilizing
            our Service, the time and date of your use of the
            Service, and other statistics.
          </Paragraph>

          <Title>Cookies</Title>

          <Paragraph>
            Cookies are files with a small amount of data that are
            commonly used as anonymous unique identifiers. These are
            sent to your browser from the websites that you visit and
            are stored on your device&apos;s internal memory.
          </Paragraph>

          <Paragraph>
            This Service does not use these “cookies” explicitly.
            However, the app may use third party code and libraries that
            use “cookies” to collect information and improve their
            services. You have the option to either accept or refuse
            these cookies and know when a cookie is being sent to your
            device. If you choose to refuse our cookies, you may not be
            able to use some portions of this Service.
          </Paragraph>

          <Title>Service Providers</Title>

          <Paragraph>
            We may employ third-party companies
            and individuals due to the following reasons:
          </Paragraph>

          <List>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </List>

          <Paragraph>
            We want to inform users of this
            Service that these third parties have access to your
            Personal Information. The reason is to perform the tasks
            assigned to them on our behalf. However, they are obligated
            not to disclose or use the information for any other
            purpose.
          </Paragraph>

          <Title>Security</Title>

          <Paragraph>
            We value your trust in providing us
            your Personal Information, thus we are striving to use
            commercially acceptable means of protecting it. But remember
            that no method of transmission over the internet, or method
            of electronic storage is 100% secure and reliable, and
            we cannot guarantee its absolute security.
          </Paragraph>

          <Title>Links to Other Sites</Title>

          <Paragraph>
            This Service may contain links to other sites. If you click
            on a third-party link, you will be directed to that site.
            Note that these external sites are not operated by
            us. Therefore, we strongly advise you to
            review the Privacy Policy of these websites.
            We have no control over and assume no
            responsibility for the content, privacy policies, or
            practices of any third-party sites or services.
          </Paragraph>

          <Title>Children’s Privacy</Title>

          <Paragraph>
            These Services do not address anyone under the age of 13.
            We do not knowingly collect personally
            identifiable information from children under 13. In the case
            we discover that a child under 13 has provided
            us with personal information,
            we immediately delete this from our servers. If you
            are a parent or guardian and you are aware that your child
            has provided us with personal information, please contact
            us so that we will be able to do
            necessary actions.
          </Paragraph>

          <Title>Changes to This Privacy Policy</Title>

          <Paragraph>
            We may update our Privacy Policy from
            time to time. Thus, you are advised to review this page
            periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy
            on this page. These changes are effective immediately after
            they are posted on this page.
          </Paragraph>

          <Title>Contact Us</Title>

          <Paragraph>
            If you have any questions or suggestions about
            our Privacy Policy, do not hesitate to contact
            us at hello@dotdotdata.com.
          </Paragraph>

        </Container>
      </div>
    </div>
  </Layout>
)

export default Privacy

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "8181dd.png" }) {
      childImageSharp {
        fluid(maxHeight: 714) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
